import { QueryClient } from '@tanstack/react-query';
import { createBrowserRouter, Navigate } from 'react-router-dom';

import { ErrorPage } from '$pages/error-page';
import { OrganizationManagementPage } from '$pages/organization-management/organization-management-page';
import { RootLayout } from '$pages/root-layout';

export function createRouter(queryClient: QueryClient) {
  return createBrowserRouter([
    {
      path: '/',
      element: <RootLayout />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: '',
          element: <Navigate to="organization-management" />,
        },
        {
          path: 'organization-management',
          element: <OrganizationManagementPage />,
          errorElement: <OrganizationManagementPage />,
        },
      ],
    },
  ]);
}
