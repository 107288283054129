import { Auth } from '@aws-amplify/auth';
import { HasChildren } from '@eppendorf/vnls-react-components';
import type { CognitoUserSession } from 'amazon-cognito-identity-js';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { ToastContainer } from 'react-toastify';

import { AppWrapper } from 'src/app-wrapper';
import './main.scss';
import 'react-toastify/dist/ReactToastify.css';

import '$shared/amplify/amplify-configure';

function AuthWrapper({ children }: HasChildren): React.ReactElement<HasChildren> {
  // This is probably not the best way and place to do this, especially since we will want user information to
  // be accessibly throughout the application, but its sufficient as an example.
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      return;
    }

    async function checkUserSession() {
      try {
        const session: CognitoUserSession = await Auth.currentSession();
        if (session) {
          setIsAuthenticated(true);
        }
      } catch (_) {
        await Auth.federatedSignIn();
      }
    }

    checkUserSession().catch((error) => {
      // eslint-disable-next-line no-console -- maybe useful for debugging
      console.error(error);
    });
  }, []);

  return (
    <React.StrictMode>
      {/* only render if authenticated to aviod unauthenticated calls right away */}
      {isAuthenticated && children}
    </React.StrictMode>
  );
}

const prepareApp = async (): Promise<ServiceWorkerRegistration | undefined> =>
  /* if (import.meta.env.PUBLIC_ENABLE_MSW === 'true') {
    const { worker } = await import('src/mocks/msw-setup-browser');
    return worker.start();
  } */

  Promise.resolve(undefined);
prepareApp().then(() => {
  const root = document.getElementById('root');
  if (root) {
    ReactDOM.createRoot(root).render(
      <AuthWrapper>
        <AppWrapper />
        <ToastContainer />
      </AuthWrapper>,
    );
  }
});
