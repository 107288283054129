import { environment } from '../../environment';

export const DEVICES_BASE_PATH = '/inventory/devices';

export const createDevicePath = (manufacturer: string, serialNumber: string) =>
  `${DEVICES_BASE_PATH}/${manufacturer}/${serialNumber}`;

// Notifications

export const NOTIFICATION_RULES_BASE_PATH = '/notification/notification-rules';

export const createNotificationRulePath = (id: string) =>
  `${NOTIFICATION_RULES_BASE_PATH}/${id}`;

// Organizations

export const ORGANIZATIONS_BASE_PATH = '/organizations';

export const createOrganizationPath = (id: string) => `${ORGANIZATIONS_BASE_PATH}/${id}`;

// Other

export const LOCALHOST_BASE_URL = 'http://localhost:5173';

export const LOCALHOST_GRAPHQL_URL = 'http://localhost:5173';

export const MIGRATION_BASE_PATH = environment.visionizeApiUrl || '';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- any is used for query params
export function objectToURLSearchParams(obj: Record<string, any>) {
  return new URLSearchParams(obj);
}

export const createPathWithQueryParams = (
  basePath: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- any is used for query params
  queryParams: Record<string, any>,
) => {
  const stringifiedQueryParams = new URLSearchParams(queryParams).toString();
  return `${basePath}?${stringifiedQueryParams}`;
};
