import {
  AddOrganizationDto,
  GetOrganizationDto,
  OrganizationWithDevices, PaginatedResponse,
  UpdateOrganizationDto,
  User,
} from '@eppendorf/vnls-user-tenant-utils';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { fetcher } from '$shared/fetch';
import {
  createOrganizationPath,
  createPathWithQueryParams,
  MIGRATION_BASE_PATH,
  objectToURLSearchParams,
  ORGANIZATIONS_BASE_PATH,
} from '$shared/utils/api-paths';

export const organizationQueryKey = 'organizations';

export const createOrganizationsQueryWithQueryParams = (queryParams = {}) => ({
  queryKey: [organizationQueryKey, ...objectToURLSearchParams(queryParams).values()],
  queryFn: async () =>
    fetcher.get<PaginatedResponse<OrganizationWithDevices>>(
      createPathWithQueryParams(ORGANIZATIONS_BASE_PATH, queryParams),
    ),
  keepPreviousData: true,
});

export const useOrganizationsWithQueryParams = (queryParams = {}) =>
  useQuery(createOrganizationsQueryWithQueryParams(queryParams));

export const createGetOrganizationQuery = (id: string) => ({
  queryKey: [organizationQueryKey, id],
  queryFn: async () => fetcher.get<GetOrganizationDto>(createOrganizationPath(id)),
  enabled: !!id,
});
export const useGetOrganization = (id: string) =>
  useQuery(createGetOrganizationQuery(id));

export const useAddOrganization = () => {
  const addOrganization = (organization: AddOrganizationDto) =>
    fetcher.post<AddOrganizationDto, GetOrganizationDto>(
      ORGANIZATIONS_BASE_PATH,
      organization,
    );

  const queryClient = useQueryClient();
  return useMutation<AxiosResponse<GetOrganizationDto>, AxiosError, AddOrganizationDto>(
    addOrganization,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([organizationQueryKey]);
      },
    },
  );
};

export const useUpdateOrganization = () => {
  const updateOrganization = (
    organization: UpdateOrganizationDto & Pick<GetOrganizationDto, 'id'>,
  ) => {
    const { id, ...organizationWithoutId } = organization;
    return fetcher.patch<UpdateOrganizationDto, GetOrganizationDto>(
      createOrganizationPath(id),
      organizationWithoutId,
    );
  };

  const queryClient = useQueryClient();
  return useMutation<
    GetOrganizationDto,
    AxiosError,
    UpdateOrganizationDto & Pick<GetOrganizationDto, 'id'>
  >(updateOrganization, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([organizationQueryKey]);

      queryClient.setQueryData([organizationQueryKey, data.id], data);
    },
  });
};

export const useDeleteOrganization = () => {
  const deleteOrganization = (id: string) =>
    fetcher.delete(`${ORGANIZATIONS_BASE_PATH}/${id}`);
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosError, string>(deleteOrganization, {
    onSuccess: () => {
      queryClient.invalidateQueries([organizationQueryKey]);
    },
  });
};

export const useMigrateOrganizations = () => {
  const migrateOrganizations = (legacyTenantId: string) =>
    fetcher.post<unknown, { tenantId: string }>(
      `${MIGRATION_BASE_PATH}/migration/migrateTenant`,
      {
        legacyTenantId,
      },
    );
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosError, string>(migrateOrganizations, {
    onSuccess: () => {
      queryClient.invalidateQueries([organizationQueryKey]);
    },
  });
};

export const useResendInvite = () => {
  const updateInviteSendAt = (id: string) =>
    fetcher.post<unknown, GetOrganizationDto>(
      `${ORGANIZATIONS_BASE_PATH}/${id}/actions/resend-invite`,
      undefined,
    );

  const queryClient = useQueryClient();
  return useMutation<AxiosResponse<GetOrganizationDto>, AxiosError, string>(
    updateInviteSendAt,
    {
      onSuccess: ({ data }) => {
        queryClient.invalidateQueries([organizationQueryKey]);

        queryClient.setQueryData([organizationQueryKey, data.id], data);
      },
      onError: (error) => {
        // eslint-disable-next-line no-console -- for debugging
        console.log(error);
      },
    },
  );
};

export const useInviteUsers = () => {
  const inviteAllUsers = (legacyTenantId: string) =>
    fetcher.post<unknown, { tenantId: string }>(
      `${MIGRATION_BASE_PATH}/migration/invite-all-users`,
      {
        legacyTenantId,
      },
    );

  const queryClient = useQueryClient();
  return useMutation<AxiosResponse<{ tenantId: string }>, AxiosError, string>(
    inviteAllUsers,
    {
      onSuccess: ({ data }) => {
        queryClient.invalidateQueries([organizationQueryKey]);

        queryClient.setQueryData([organizationQueryKey, data.tenantId], data);
      },
      onError: (error) => {
        // eslint-disable-next-line no-console -- for debugging
        console.log(error);
      },
    },
  );
};

export const downloadMigrationReport = async (legacyTenantId: string) => {
  try {
    return await fetcher.get<{ url: string }>(`${MIGRATION_BASE_PATH}/migration/report/${legacyTenantId}`);
  } catch (error) {
    return { url: null };
  }
}

export const enableSupportAccount = (organizationId: string) => useMutation<AxiosResponse, AxiosError>({
  mutationFn: () => fetcher.post(`${ORGANIZATIONS_BASE_PATH}/${organizationId}/support/user`, null),
});

export const disableSupportAccount = (organizationId: string) => useMutation<AxiosResponse, AxiosError>({
  mutationFn: () => fetcher.delete(`${ORGANIZATIONS_BASE_PATH}/${organizationId}/support/user`),
});

export const getSupportAccount = async (organizationId: string) => {
  try {
    return await fetcher.get<User>(`${ORGANIZATIONS_BASE_PATH}/${organizationId}/support/user`);
  } catch (error) {
    return null;
  }
}
