import { QueryClientProvider } from '@tanstack/react-query';
import { I18nextProvider } from 'react-i18next';
import { RouterProvider } from 'react-router-dom';

import i18next from '$shared/i18n/i18n';
import { queryClient } from '$shared/query/query-client';

import { createRouter } from 'src/router';

export function AppWrapper() {
  return (
    <I18nextProvider i18n={i18next}>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={createRouter(queryClient)} />

        {/* {import.meta.env.PUBLIC_ENABLE_REACT_QUERY_DEVTOOLS === 'true' && (
          <ReactQueryDevtools initialIsOpen={false} />
        )} */}
      </QueryClientProvider>
    </I18nextProvider>
  );
}
