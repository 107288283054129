import { HasChildren } from "@eppendorf/vnls-react-components";
import { ReactElement } from "react";

function OverviewWrapper({ children }: HasChildren): ReactElement {
  return <div className="box">{children}</div>;
}

function OverviewHeader({ children }: HasChildren): ReactElement {
  return (
    <div className="flex flex__jc--space-between flex__ai--center m-bottom-xxxl">
      {children}
    </div>
  );
}

function OverviewBody({ children }: HasChildren): ReactElement {
  return <div>{children}</div>;
}

export const Overview = Object.assign(OverviewWrapper, {
  Header: OverviewHeader,
  Body: OverviewBody,
});
