import {
  AddOrganizationDto,
  Organization,
  UpdateOrganizationDto,
} from '@eppendorf/vnls-user-tenant-utils';

import { OrganizationFormProfile } from '$features/organizations/form/organization-form-profile';

const mapOrganizationToFormValues = (
  organization: Organization,
): OrganizationFormProfile => ({
  ...organization,
});

const mapFormValuesToUpdateRequest = (
  id: string,
  {
    name,
    contactFirstName,
    contactLastName,
    contactEmail,
    adminEmail,
    adminFirstName,
    adminLastName,
  }: OrganizationFormProfile,
): UpdateOrganizationDto & Pick<Organization, 'id'> => ({
  id,
  contactEmail,
  contactFirstName,
  name,
  contactLastName,
  adminEmail,
  adminFirstName,
  adminLastName,
});

const mapFormValuesToCreateRequest = ({
  name,
  contactFirstName,
  contactLastName,
  contactEmail,
  adminFirstName,
  adminLastName,
  adminEmail,
}: OrganizationFormProfile): AddOrganizationDto => ({
  name,
  contactFirstName,
  contactLastName,
  contactEmail,
  adminFirstName,
  adminLastName,
  adminEmail,
});

export const OrganizationFormMappers = {
  mapOrganizationToFormValues,
  mapFormValuesToUpdateRequest,
  mapFormValuesToCreateRequest,
};
