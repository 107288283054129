import { Input, Label, ValidationMessage } from '@eppendorf/vnls-react-components';
import { Organization } from '@eppendorf/vnls-user-tenant-utils';
import { FunctionComponentElement, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { OrganizationFormMappers } from './organization-form-mapper';
import { OrganizationFormProfile } from './organization-form-profile';

interface Props {
  onSubmit: (formValues: OrganizationFormProfile) => void;
  organization?: Organization;
}

export const OrganizationFormId = 'organizationForm';

export function OrganizationForm({
  onSubmit,
  organization,
}: Props): FunctionComponentElement<Props> {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors: formErrors, isSubmitting },
  } = useFormContext<OrganizationFormProfile>();

  useEffect(() => {
    if (organization) {
      reset(OrganizationFormMappers.mapOrganizationToFormValues(organization));
    }
  }, [reset, organization]);

  async function handleFormSubmit(form: OrganizationFormProfile): Promise<void> {
    onSubmit(form);
  }

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} id={OrganizationFormId}>
      <div className="m-bottom-m">
        <Label htmlFor="name">{t('addOrganization.name')}*</Label>
        <Input
          invalid={!!formErrors.name}
          id="name"
          type="text"
          className="input m-top-xs m-bottom-xs"
          disabled={isSubmitting}
          // eslint-disable-next-line react/jsx-props-no-spreading -- spread is needed for react-hook-form
          {...register('name', {
            required: true,
          })}
        />
        {formErrors.name?.type === 'required' && (
          <ValidationMessage>{t('validationMessages.required')}</ValidationMessage>
        )}
      </div>

      <div className="m-bottom-m">
        <Label htmlFor="contactFirstName">{t('addOrganization.contactFirstName')}*</Label>
        <Input
          invalid={!!formErrors.contactFirstName}
          id="contactFirstName"
          type="text"
          className="input m-top-xs m-bottom-xs"
          disabled={isSubmitting}
          // eslint-disable-next-line react/jsx-props-no-spreading -- spread is needed for react-hook-form
          {...register('contactFirstName', {
            required: true,
          })}
        />
        {formErrors.contactFirstName?.type === 'required' && (
          <ValidationMessage>{t('validationMessages.required')}</ValidationMessage>
        )}
      </div>

      <div className="m-bottom-m">
        <Label htmlFor="contactLastName">{t('addOrganization.contactLastName')}*</Label>
        <Input
          invalid={!!formErrors.contactLastName}
          id="contactLastName"
          type="text"
          className="input m-top-xs m-bottom-xs"
          disabled={isSubmitting}
          // eslint-disable-next-line react/jsx-props-no-spreading -- spread is needed for react-hook-form
          {...register('contactLastName', {
            required: true,
          })}
        />
        {formErrors.contactLastName?.type === 'required' && (
          <ValidationMessage>{t('validationMessages.required')}</ValidationMessage>
        )}
      </div>

      <div className="m-bottom-m">
        <Label htmlFor="contactEmail">{t('addOrganization.contactEmail')}*</Label>
        <Input
          invalid={!!formErrors.contactEmail}
          id="contactEmail"
          type="text"
          className="input m-top-xs m-bottom-xs"
          disabled={isSubmitting}
          // eslint-disable-next-line react/jsx-props-no-spreading -- spread is needed for react-hook-form
          {...register('contactEmail', {
            required: true,
            maxLength: 120,
            // eslint-disable-next-line regexp/no-super-linear-backtracking -- the implementation used seems to be fine and anyway it would only slow down the user's browser
            pattern: /^\S+@\S+\.\S{2,}$/,
            setValueAs: (v) => v.trim(),
          })}
        />
        {formErrors.contactEmail?.type === 'required' && (
          <ValidationMessage>{t('validationMessages.required')}</ValidationMessage>
        )}
        {formErrors.contactEmail?.type === 'maxLength' && (
          <ValidationMessage>
            {t('addOrganization.errorMessageContactEmailMaxLength')}
          </ValidationMessage>
        )}
        {formErrors.contactEmail?.type === 'pattern' && (
          <ValidationMessage>
            {t('addOrganization.errorMessageContactEmailWrongFormat')}
          </ValidationMessage>
        )}
      </div>

      <div className="m-bottom-m">
        <Label htmlFor="adminFirstName">{t('addOrganization.adminFirstName')}*</Label>
        <Input
          invalid={!!formErrors.adminFirstName}
          id="adminFirstName"
          type="text"
          className="input m-top-xs m-bottom-xs"
          disabled={isSubmitting}
          // eslint-disable-next-line react/jsx-props-no-spreading -- spread is needed for react-hook-form
          {...register('adminFirstName', {
            required: true,
          })}
        />
        {formErrors.adminFirstName?.type === 'required' && (
          <ValidationMessage>{t('validationMessages.required')}</ValidationMessage>
        )}
      </div>

      <div className="m-bottom-m">
        <Label htmlFor="adminLastName">{t('addOrganization.adminLastName')}*</Label>
        <Input
          invalid={!!formErrors.adminLastName}
          id="adminLastName"
          type="text"
          className="input m-top-xs m-bottom-xs"
          disabled={isSubmitting}
          // eslint-disable-next-line react/jsx-props-no-spreading -- spread is needed for react-hook-form
          {...register('adminLastName', {
            required: true,
          })}
        />
        {formErrors.adminLastName?.type === 'required' && (
          <ValidationMessage>{t('validationMessages.required')}</ValidationMessage>
        )}
      </div>

      <div className="m-bottom-m">
        <Label htmlFor="adminEmail">{t('addOrganization.adminEmail')}*</Label>
        <Input
          invalid={!!formErrors.adminEmail}
          id="adminEmail"
          type="text"
          className="input m-top-xs m-bottom-xs"
          disabled={isSubmitting}
          // eslint-disable-next-line react/jsx-props-no-spreading -- spread is needed for react-hook-form
          {...register('adminEmail', {
            required: true,
            maxLength: 120,
            // eslint-disable-next-line regexp/no-super-linear-backtracking -- the implementation used seems to be fine and anyway it would only slow down the user's browser
            pattern: /^\S+@\S+\.\S{2,}$/,
            setValueAs: (v) => v.trim(),
          })}
        />
        {formErrors.adminEmail?.type === 'required' && (
          <ValidationMessage>{t('validationMessages.required')}</ValidationMessage>
        )}
        {formErrors.adminEmail?.type === 'conflict' && (
          <ValidationMessage>{formErrors.adminEmail?.message}</ValidationMessage>
        )}
        {formErrors.adminEmail?.type === 'maxLength' && (
          <ValidationMessage>
            {t('addOrganization.errorMessageAdminEmailMaxLength')}
          </ValidationMessage>
        )}
        {formErrors.adminEmail?.type === 'pattern' && (
          <ValidationMessage>
            {t('addOrganization.errorMessageAdminEmailWrongFormat')}
          </ValidationMessage>
        )}
      </div>
    </form>
  );
}
