import {
  ButtonBase,
  HasChildren,
} from '@eppendorf/vnls-react-components';
import {
  ColumnDef,
  Row,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { ReactElement } from 'react';

import './sortable-table.scss';

type ArrayElement<ArrayType extends readonly unknown[]> =
    | (ArrayType extends readonly (infer ElementType)[] ? ElementType : never)
    | unknown;

export interface SortableTableProps<
    InputArray extends readonly unknown[],
    InputArrayElementType extends ArrayElement<InputArray>,
> extends HasChildren {
  data: InputArrayElementType[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- react table is not behaving nicely otherwise
  columns: ColumnDef<InputArrayElementType, any>[];
  onRowClick?: (element: InputArrayElementType) => void;
  onSortChange?: (sortKey: string) => void;
  isSelectedRow?: (rowData: InputArrayElementType) => boolean;
  currentSortKey?: string;
}

export function SortableTable<T extends readonly unknown[], K extends ArrayElement<T>>({
                                                                                         data,
                                                                                         columns,
                                                                                         onRowClick,
                                                                                         onSortChange,
                                                                                         currentSortKey,
                                                                                         isSelectedRow,
                                                                                       }: SortableTableProps<T, K>): ReactElement {
  const table = useReactTable<K>({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  function handleRowClick(row: Row<K>) {
    if (onRowClick) {
      onRowClick(row.original);
    }
  }

  function handleRowSelection(rowData: K): boolean {
    if (isSelectedRow) {
      return isSelectedRow(rowData);
    }
    return false;
  }

  return (
      <>
        <table className="table">
          <thead>
          {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                    <th key={header.id} className={header.id === 'device' ? 'w-full' : ''}>
                      {header.isPlaceholder ? null : (
                          <span>
                      {header.column.columnDef.meta?.isSortable ? (
                          <ButtonBase
                              onClick={() =>
                                  header.column.columnDef.meta?.isSortable &&
                                  onSortChange &&
                                  onSortChange(header.id)
                              }
                              className={currentSortKey?.includes(header.id) ? 'active' : ''}
                          >
                            {flexRender(
                                header.column.columnDef.header,
                                header.getContext(),
                            )}

                          </ButtonBase>
                      ) : (
                          flexRender(header.column.columnDef.header, header.getContext())
                      )}
                    </span>
                      )}
                    </th>
                ))}
              </tr>
          ))}
          </thead>
          <tbody>
          {table.getRowModel().rows.map((row) => (
              <tr
                  key={row.id}
                  className={
                    handleRowSelection(row.original)
                        ? 'selected-row interactive'
                        : 'interactive'
                  }
                  onClick={() => handleRowClick(row)}
              >
                {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                ))}
              </tr>
          ))}
          </tbody>
        </table>
        <div className="m-top-l" />
      </>
  );
}
