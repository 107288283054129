import { Organization } from '@eppendorf/vnls-user-tenant-utils';
import { FunctionComponentElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
  ConfirmDialogIconType,
  ConfirmationDialog,
} from '$components/confirmation-dialog/confirmation-dialog';
import { displayOrganization } from '$features/add-organization/add-organization-dialog/screens/display-organization.util';

export interface SuccessScreenProps {
  addedOrganization?: Organization;
}

export function SuccessScreen({
  addedOrganization,
}: SuccessScreenProps): FunctionComponentElement<SuccessScreenProps> {
  const { t } = useTranslation();

  return (
    <ConfirmationDialog
      title={t('addOrganization.addOrganizationSuccess')}
      iconType={ConfirmDialogIconType.success}
      dialogDescription={
        <Trans
          i18nKey="addOrganization.addOrganizationSuccessDescription"
          values={{
            name: displayOrganization(addedOrganization),
          }}
          components={{ bold: <span className="font-weight-bold" /> }}
        />
      }
    />
  );
}
