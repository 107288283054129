import './organization-detail-row.scss';
import React from 'react';

interface Props {
  label: string;
  value: string;
  children?: React.ReactNode;
  testId?: string;
}

export function OrganizationDetailRow({ label, value, children, testId }: Props) {
  return (
    <div className={`label-value-row org-detail-row-${label}`} data-testId={testId}>
      <p className="label">{label}</p>

      { value && <p className="value">{value}</p> }

      {children}
    </div>
  );
}
