import { Badge, BadgeProps } from '@eppendorf/vnls-react-components';
import { InvitationStatus as AdminInvitationStatus } from '@eppendorf/vnls-user-tenant-utils';
import { useTranslation } from 'react-i18next';

interface Props extends Pick<BadgeProps, 'bordered'> {
  status: AdminInvitationStatus;
}

export function InvitationStatus({ status, bordered }: Props) {
  const { t } = useTranslation();

  function getStatusText(adminInvitationStatus: AdminInvitationStatus) {
    switch (adminInvitationStatus) {
      case AdminInvitationStatus.Active:
        return 'adminStatus.active';
      case AdminInvitationStatus.Invited:
        return 'adminStatus.invited';
      case AdminInvitationStatus.Migrated:
        return 'adminStatus.migrated';
      case AdminInvitationStatus.Imported:
        return 'adminStatus.imported';
      default:
        return 'adminStatus.unknown';
    }
  }

  function getBadgeColor(inviteStatus: AdminInvitationStatus) {
    switch (inviteStatus) {
      case AdminInvitationStatus.Active:
        return 'green';
      case AdminInvitationStatus.Imported:
        return 'purple';
      default:
        return 'orange';
    }
  }

  return (
    <Badge
      bordered={bordered}
      color={getBadgeColor(status)}
    >
      {t(getStatusText(status))}
    </Badge>
  );
}
