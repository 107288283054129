import { useState } from 'react';

import { useQueryString } from '$shared/custom-hooks/use-query-string';

import { SIDECAR_ROUTE_PARAMS } from '../../sidecar-route-params';

export const useSidecar = () => {
  const [isSidecarOpen, setIsSidecarOpen] = useState(true);
  const { setQueryParams } = useQueryString();

  const handleSidecarOpenChange = () => {
    setIsSidecarOpen((prevState) => !prevState);

    if (isSidecarOpen) {
      setQueryParams({
        sidecarRoute: '',
        [SIDECAR_ROUTE_PARAMS.organization.id]: '',
      });
    }
  };

  return { isSidecarOpen, handleSidecarOpenChange };
};
