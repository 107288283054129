import { Dialog, DialogProps } from '@eppendorf/vnls-react-components';
import { FunctionComponentElement, useEffect, useState } from 'react';

import {
  AddOrganizationDialogContent,
  OrganizationForms,
} from '$features/add-organization/add-organization-dialog/add-organization-dialog-content';

export interface AddOrganizationDialogWrapperProps {
  isOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
  trigger?: DialogProps['trigger'];
}

export function AddOrganizationDialogWrapper({
  isOpen = false,
  onOpenChange,
  trigger,
}: AddOrganizationDialogWrapperProps): FunctionComponentElement<AddOrganizationDialogWrapperProps> {
  const [activeScreen, setActiveScreen] = useState<OrganizationForms>(
    OrganizationForms.OrganizationForm,
  );

  function handleOpenChange(open: boolean): void {
    if (onOpenChange) onOpenChange(open);
  }

  function handleScreenChange(screen: OrganizationForms): void {
    setActiveScreen(screen);
  }

  function resetDialog(): void {
    setActiveScreen(OrganizationForms.OrganizationForm);
  }

  useEffect(() => resetDialog(), [isOpen]);

  return (
    <Dialog
      isOpen={isOpen}
      onOpenChange={(open) => handleOpenChange(open)}
      trigger={trigger}
      className="w-max-4xl"
      hideCloseButton={activeScreen === OrganizationForms.SuccessScreen}
    >
      <AddOrganizationDialogContent
        activeScreen={activeScreen}
        setActiveScreen={(screen: OrganizationForms) => handleScreenChange(screen)}
      />
    </Dialog>
  );
}
