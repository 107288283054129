import i18next from '$shared/i18n/i18n';

export function createCurrentDateString(): string {
  const date = new Date();
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
}

export function getLocalizedDateFromUtcUnixTime(utcUnixTime: number): string {
  return i18next.t('translation:shared.intlDateTime', {
    val: new Date(utcUnixTime),
    formatParams: {
      val: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      },
    },
  });
}

export function getLocalizedDateFromUtcString(dateString: string): string {
  return i18next.t('translation:shared.intlDateTime', {
    val: new Date(dateString),
    formatParams: {
      val: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      },
    },
  });
}
