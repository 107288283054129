import { Organization } from '@eppendorf/vnls-user-tenant-utils';
import { FunctionComponentElement, useState } from 'react';

import { AddOrganizationScreen } from '$features/add-organization/add-organization-dialog/screens';
import { SuccessScreen } from '$features/add-organization/add-organization-dialog/screens/success-screen';

export enum OrganizationForms {
  'OrganizationForm',
  'SuccessScreen',
}

export interface AddOrganizationContentProps {
  activeScreen: OrganizationForms;
  setActiveScreen: (screen: OrganizationForms) => void;
}

export function AddOrganizationDialogContent({
  activeScreen,
  setActiveScreen,
}: AddOrganizationContentProps): FunctionComponentElement<void> {
  const [addedOrganization, setAddedOrganization] = useState<Organization>();

  const handleOnOrganizationAdded = (organization: Organization) => {
    setAddedOrganization(organization);
    setActiveScreen(OrganizationForms.SuccessScreen);
  };

  return (
    <>
      {activeScreen === OrganizationForms.OrganizationForm && (
        <AddOrganizationScreen onOrganizationAdded={handleOnOrganizationAdded} />
      )}

      {activeScreen === OrganizationForms.SuccessScreen && (
        <SuccessScreen addedOrganization={addedOrganization} />
      )}
    </>
  );
}
