import { parse, ParsedQs, stringify } from 'qs';
import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const PARSE_OPTIONS = {
  ignoreQueryPrefix: true,
};

export const useQueryString = <T = ParsedQs>() => {
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = useMemo(
    () => parse(location.search, PARSE_OPTIONS),
    [location.search],
  ) as unknown as T;
  const getQueryParams = useCallback(() => queryParams, [queryParams]);

  const setQueryParams = useCallback(
    (searchParams: Partial<T>, newPathname?: string, isReplace = false) => {
      const { search, pathname, hash } = window.location;
      const params = {
        ...parse(search, PARSE_OPTIONS),
        ...searchParams,
      };
      const newLocation = {
        hash,
        pathname: newPathname || pathname,
        search: stringify(
          Object.keys(params).reduce((result, paramKey) => {
            if (
              (typeof params[String(paramKey)] === 'string' || Array.isArray(params[String(paramKey)])) &&
              params[String(paramKey)]?.length === 0
            ) {
              return {
                ...result,
                [paramKey]: null,
              };
            }

            return result;
          }, params),
          { skipNulls: true },
        ),
      };

      return newPathname && !isReplace
        ? navigate(newLocation)
        : navigate(newLocation, { replace: true });
    },
    [navigate],
  );

  return { getQueryParams, setQueryParams };
};
