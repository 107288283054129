import {
  Button,
  Dialog,
  DialogProps,
  Icon,
  IconSizeClasses,
} from '@eppendorf/vnls-react-components';
import { useTranslation } from 'react-i18next';

interface Props extends Pick<DialogProps, 'isOpen'> {
  onSave: () => void;
  onDiscard: () => void;
}

export function ConfirmUnsavedChangesDialog({ isOpen, onSave, onDiscard }: Props) {
  const { t } = useTranslation();

  return (
    <Dialog isOpen={isOpen} hideCloseButton className="dialog-confirm-unsafed-changes">
      <div className="flex flex__dir--column flex__ai--center">
        <Icon
          name="info"
          className="bg-orange-500 m-bottom-xxl"
          size={IconSizeClasses.XLarge}
        />

        <div className="title">{t('unsaved.title')}</div>

        <div className="body-regular">{t('unsaved.message')}</div>
      </div>

      <Dialog.Actions>
        <Button onClick={onSave} variant="secondary">
          {t('unsaved.save')}
        </Button>
        <Button onClick={onDiscard} variant="primary">
          {t('unsaved.discard')}
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
}
