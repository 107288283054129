import { Auth } from '@aws-amplify/auth';
import { Button, Icon, IconSizeClasses, Tooltip } from '@eppendorf/vnls-react-components';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

export function Header(): ReactElement {
  const { t } = useTranslation();
  return (
    <header className="flex flex__jc--space-between p-y-m p-x-xxxl border-bottom-s border-solid border-color-gray-300 bg-white">
      <Icon name="vn-logo-long" className="sidebar__logo--long" />
      <Button
        id="logout"
        onClick={() => Auth.signOut()}
        leadingIcon={
          <Tooltip
            trigger={
              <div>
                <Icon
                  name="logout"
                  size={IconSizeClasses.XSmall}
                  alt={t('shared.logout')}
                />
              </div>
            }
          >
            {t('shared.logout')}
          </Tooltip>
        }
        variant="tertiary"
      />
    </header>
  );
}
