import { Outlet, useNavigation } from 'react-router-dom';

import { queryClient } from '$shared/query/query-client';

import { SidecarRouter } from '$components/sidecar-router/sidecar-router';

import { Header } from '../components/header/header';
import { Loader } from '../components/loader/loader';

import { createSidecarRoutes } from 'src/sidecar-routes';

export function RootLayout() {
  const navigation = useNavigation();

  return (
    <>
      {navigation.state === 'loading' ? <Loader /> : <span />}

      <Header />

      <main>
        <Outlet />
        <SidecarRouter router={createSidecarRoutes(queryClient)} />
      </main>
    </>
  );
}
