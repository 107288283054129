import { ReactElement } from "react";
import { Outlet, useRouteError } from "react-router-dom";

import { OrganizationOverview } from "$features/organization-overview/organization-overview";

export function OrganizationManagementPage(): ReactElement {
  const routeError = useRouteError();
  return (
    <>
      <OrganizationOverview />
      {!routeError && <Outlet />}
    </>
  );
}
