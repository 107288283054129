import { QueryClient } from '@tanstack/react-query';

import { SidecarRoute } from '$components/sidecar-router/sidecar-router.definitions';
import { OrganizationDetailPage } from '$pages/organization-management/detail/organization-detail-page';

import { SIDECAR_ROUTES } from 'src/sidecar-routes.enum';

export function createSidecarRoutes(
  _queryClient: QueryClient,
): Record<SIDECAR_ROUTES, SidecarRoute> {
  return {
    [SIDECAR_ROUTES.Organization]: {
      element: <OrganizationDetailPage />,
    },
  };
}
